import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-tooltip',
  standalone: true,
  imports: [
    NgbTooltip,
    NgClass
  ],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss'
})
export class TooltipComponent {
  @Input() tooltipText = '';
  @Input() iconClass = 'icon ic-tiny-lg ic-info-circle';
  @Input() placement = 'top';
  @Input() widthAuto = false;
  @Input() customContent = false;
  @Input() isDisabled = false;
  @Output() action = new EventEmitter<boolean>();

  onClick(): void {
    this.action.emit(true);
  }
}
